<template>
    <div class="table">
        <div :class="['topbar', { expanded: isExpanded }]">
            <div class="sales-information">
                <button class="nav sales-button" :class="{ active: isExpanded }" @click="toggleSalesSection"></button>
                <button class="nav mail" @click="sendReportMail"></button>
                <button class="nav refresh" @click="refresh"></button>
            </div>
            <div class="content">
                <div class="navBar">
                    <div class="nav-elements">
                        <button class="printer"></button>
                        <div class="timeframe">
                            <span class="title">Mañana</span>
                            <span class="description">Apertura - 16.00h</span>
                        </div>
                    </div>
                    <div class="nav-elements">
                        <button class="printer"></button>
                        <div class="timeframe">
                            <span class="title">Tarde</span>
                            <span class="description">16.00h - cierre</span>
                        </div>
                    </div>
                    <div class="nav-elements">
                        <button class="printer"></button>
                        <div class="timeframe">
                            <span class="title">Total</span>
                            <span class="description">Todo el día</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-sections hide-scrollbar" ref="scrollContainer4">
            <div v-if="isExpanded" class="sales-section">
                <div class="sales-text">
                    <span> {{ $t('production.sales.projected') }}</span>
                    <span> {{ $t('production.sales.real') }}</span>
                    <span> {{ $t('production.sales.difference') }}</span>
                    <span> {{ $t('production.sales.percentage_difference') }}</span>
                </div>
                <div class="sales-content">
                    <div v-if="forecast.sales_forecast" class="sales-forecast" ref="scrollContainer3">
                        <div class="row sales-forecast-projected">
                            <span>{{ summary_hours.forecast.morning }}€</span>
                            <span>{{ summary_hours.forecast.afternoon }}€</span>

                            <span>{{ summary_hours.forecast.total }} €</span>
                        </div>
                        <div class="row sales-forecast-real">
                            <span v-if="summary_hours.actual.morning"> {{ summary_hours.actual.morning }}€ </span>
                            <span v-else>-</span>
                            <span v-if="summary_hours.actual.afternoon"> {{ summary_hours.actual.afternoon }}€ </span>
                            <span v-else>-</span>

                            <span v-if="forecast.sales_forecast">{{ summary_hours.actual.total }} €</span>
                        </div>
                        <div class="row sales-forecast-difference">
                            <span v-if="summary_hours.diff.morning" :class="{ positive: summary_hours.diff.morning > 0, negative: summary_hours.diff.morning < 0 }"> {{ summary_hours.diff.morning }}€ </span>
                            <span v-else>-</span>
                            <span v-if="summary_hours.diff.afternoon" :class="{ positive: summary_hours.diff.afternoon > 0, negative: summary_hours.diff.afternoon < 0 }"> {{ summary_hours.diff.afternoon }}€ </span>
                            <span v-else>-</span>

                            <span v-if="summary_hours.diff.total" :class="{ positive: summary_hours.diff.total > 0, negative: summary_hours.diff.total < 0 }"> {{ summary_hours.diff.total }} € </span>
                            <span v-else>-</span>
                        </div>
                        <div class="row sales-forecast-difference-percentatge">
                            <span v-if="summary_hours.diff_delta.morning" :class="{ positive: summary_hours.diff_delta.morning > 0, negative: summary_hours.diff_delta.morning < 0 }"> {{ summary_hours.diff_delta.morning }} % </span>
                            <span v-else>-</span>
                            <span v-if="summary_hours.diff_delta.afternoon" :class="{ positive: summary_hours.diff_delta.afternoon > 0, negative: summary_hours.diff_delta.afternoon < 0 }"> {{ summary_hours.diff_delta.afternoon }} % </span>
                            <span v-else>-</span>

                            <span v-if="summary_hours.diff_delta.total" :class="{ positive: summary_hours.diff_delta.total > 0, negative: summary_hours.diff_delta.total < 0 }"> {{ summary_hours.diff_delta.total }} %</span>
                            <span v-else>-</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="product-content">
                <div class="products">
                    <div class="item" v-for="product in filteredProducts" :key="product.id">
                        <div class="icon" :style="{ backgroundImage: 'url(' + product.image + ')' }"></div>
                        <div class="content">
                            <div class="name">
                                {{ product.name }}
                            </div>
                            <div class="container">{{ product.containers[product.active_container] ? product.containers[product.active_container].name : '' }}</div>
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div class="forecast">
                        <div class="item" v-for="product in filteredProducts" :key="product.id" ref="scrollContainer">
                            <span class="value" :class="{ current: currentTimeframe == 'morning' }">{{ getTotal(product.id).morning }}</span>
                            <span class="value" :class="{ current: currentTimeframe == 'afternoon' }">{{ getTotal(product.id).afternoon }}</span>
                            <span class="value">{{ getTotal(product.id).total }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'preparationView',
    data() {
        return {
            isExpanded: false
        }
    },
    props: {
        products: {
            type: Array
        },
        date: { type: String }
    },
    computed: {
        forecast() {
            return this.$store.getters['production/getForecast']
        },
        currentTimeframe() {
            // If hour is before 16:00, it's morning. If not, it's afternoon. Only for today
            if (moment().isSame(this.date, 'day')) {
                return moment().hour() < 16 ? 'morning' : 'afternoon'
            }
            return ''
        },
        summary() {
            if (this.forecast.summary && this.forecast.sales_forecast) {
                var current_level = false
                var current_forecast = 0

                if (true || (moment(this.date).isSame(moment(), 'day') && this.forecast.pmix_real && this.forecast.pmix_real.levels)) {
                    var key = false
                    if (moment(this.date).isSame(moment(), 'day')) {
                        key = moment(Math.floor(moment().unix() / 1800) * 1800 * 1000).format('YYYY-MM-DD HH:mm:ss')
                    } else {
                        key = moment(Math.floor(moment(this.date).add('days', 1).unix() / 1800) * 1800 * 1000).format('YYYY-MM-DD HH:mm:ss')
                    }

                    for (var idx in this.forecast.sales_forecast.general.sales_forecast) {
                        if (idx < key) {
                            current_forecast += this.forecast.sales_forecast.general.sales_forecast[idx]
                            // current_forecast += this.forecast.sales_forecast.delivery.sales_forecast[idx]
                        }
                    }

                    current_forecast = Math.round(current_forecast, 2)
                }

                var current_sales = this.forecast.sales_real ? this.forecast.sales_real.general.total : 0

                return {
                    date: moment(this.forecast.summary.ref_general).isValid() ? moment(this.forecast.summary.ref_general).format('DD/MM/YYYY') : this.forecast.summary.ref_general,
                    date_delivery: moment(this.forecast.summary.ref_delivery).isValid() ? moment(this.forecast.summary.ref_delivery).format('DD/MM/YYYY') : this.forecast.summary.ref_delivery,
                    base: this.forecast.summary.sales_base,
                    forecast: Math.round(this.forecast.summary.total),
                    current_forecast: current_forecast,
                    current_sales: current_sales,
                    diff: current_sales - current_forecast,
                    current: this.forecast.sales_real ? this.forecast.sales_real.general.total : 0,
                    current_delta: Math.round(((current_sales - current_forecast) / current_forecast) * 100)
                }
            }
            return {}
        },
        summary_hours() {
            var result = {
                forecast: { morning: 0, afternoon: 0, total: 0 },
                forecast_current: { morning: 0, afternoon: 0, total: 0 },
                actual: { morning: 0, afternoon: 0, total: 0 },
                diff: { morning: 0, afternoon: 0, total: 0 },
                diff_delta: { morning: 0, afternoon: 0, total: 0 }
            }

            var key = false
            if (moment(this.date).isSame(moment(), 'day')) {
                key = moment(Math.floor(moment().unix() / 1800) * 1800 * 1000).format('YYYY-MM-DD HH:mm:ss')
            } else {
                key = moment(Math.floor(moment(this.date).add('days', 1).add('hours', 4).unix() / 1800) * 1800 * 1000).format('YYYY-MM-DD HH:mm:ss')
            }

            if (this.forecast.summary && this.forecast.sales_forecast) {
                const salesForecast = this.forecast.sales_forecast.general.sales_forecast
                let forecast = { morning: 0, afternoon: 0, total: 0 }
                let forecast_current = { morning: 0, afternoon: 0, total: 0 }
                let current = { morning: 0, afternoon: 0, total: 0 }
                let diff = { morning: 0, afternoon: 0, total: 0 }
                let diff_delta = { morning: 0, afternoon: 0, total: 0 }

                let hours = []
                if (this.forecast.sales_real) {
                    for (var idx in this.forecast.sales_real.general.sales) {
                        hours.push(moment(idx).format('YYYY-MM-DD HH:mm:ss'))
                    }
                }
                for (var idx in salesForecast) {
                    if (!hours.includes(idx)) hours.push(idx)
                }

                for (var i in hours) {
                    var idx = hours[i]
                    const isMorning = moment(idx).unix() < moment(this.date).add('hours', 16).unix()
                    let idx_sales = moment(idx).format('YYYY-MM-DD HH:mm')

                    let val_forecast = this.forecast.sales_forecast.general.sales_forecast[idx] ? this.forecast.sales_forecast.general.sales_forecast[idx] : 0
                    let val_real = this.forecast.sales_real ? this.forecast.sales_real.general.sales[idx_sales] : 0
                    let val_diff = val_real - val_forecast

                    if (isMorning) {
                        forecast.morning += val_forecast
                        if (idx < key) {
                            forecast_current.morning += val_forecast
                            current.morning += val_real
                            diff.morning += val_diff
                        }
                    } else {
                        forecast.afternoon += val_forecast
                        if (idx < key) {
                            current.afternoon += val_real
                            diff.afternoon += val_diff
                            forecast_current.afternoon += val_forecast
                        }
                    }
                    forecast.total += val_forecast

                    if (idx < key) {
                        current.total += val_real
                        diff.total += val_diff
                        forecast_current.total += val_forecast
                    }

                    result.forecast = forecast
                    result.actual = current
                    result.diff = diff
                    result.forecast_current = forecast_current
                }

                result.forecast.morning = Math.round(result.forecast.morning, 2)
                result.forecast.afternoon = Math.round(result.forecast.afternoon, 2)
                result.forecast.total = Math.round(result.forecast.total, 2)

                result.forecast_current.morning = Math.round(result.forecast_current.morning, 2)
                result.forecast_current.afternoon = Math.round(result.forecast_current.afternoon, 2)
                result.forecast_current.total = Math.round(result.forecast_current.total, 2)

                result.actual.morning = Math.round(result.actual.morning, 2)
                result.actual.afternoon = Math.round(result.actual.afternoon, 2)
                result.actual.total = Math.round(result.actual.total, 2)

                result.diff.morning = Math.round(result.diff.morning, 2)
                result.diff.afternoon = Math.round(result.diff.afternoon, 2)
                result.diff.total = Math.round(result.diff.total, 2)

                if (result.diff.total != 0) {
                    result.diff_delta.morning = Math.round((result.diff.morning / result.forecast_current.morning) * 100, 2)
                    result.diff_delta.afternoon = Math.round((result.diff.afternoon / result.forecast_current.afternoon) * 100, 2)
                    result.diff_delta.total = Math.round((result.diff.total / result.forecast_current.total) * 100, 2)
                }
            }
            return result
        },
        filteredProducts() {
            return this.products.filter((product) => [1, 2, 3].includes(product.type))
        }
    },
    methods: {
        getTotal(productId) {
            var product = this.filteredProducts.find((item) => item.id === productId)

            var units = product.containers[product.active_container].units
            var factor = 0.25
            if (this.forecast && this.forecast.products && typeof this.forecast.products.preparation[productId] !== 'undefined') {
                return {
                    total: Math.ceil(this.forecast.products.preparation[productId].total / units / factor) * factor,
                    morning: Math.ceil(this.forecast.products.preparation[productId].morning / units / factor) * factor,
                    afternoon: Math.ceil(this.forecast.products.preparation[productId].afternoon / units / factor) * factor
                }
            }

            return {
                total: '-',
                morning: '-',
                afternoon: '-'
            }
        },
        toggleSalesSection() {
            this.isExpanded = !this.isExpanded

            var self = this
            this.$nextTick(() => {
                if (self.$refs.scrollContainer4) {
                    self.$refs.scrollContainer4.scrollTo({ top: 0, behavior: 'smooth' })
                }
            })
        },
        sendReportMail() {
            const self = this
            const employee = this.$store.getters['loginUser/getUserInformation']
            this.$popup.message({
                message: self.$t('popup.pls.message', { date: moment(this.date).format('DD [de] MMMM [del] YYYY') }),
                textSave: self.$t('popup.pls.confirm'),
                callSave: function () {
                    self.$store.dispatch('production/exportForecast', { date: self.date })
                }
            })
        },
        refresh() {
            this.isExpanded = false
            this.loadForecast()
        },
        loadForecast() {
            let self = this
            this.$overlay.loading()
            this.$store.dispatch('production/loadForecast', this.date).then(() => {
                self.$overlay.hide()
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.table {
    display: flex;
    flex-direction: column;
    height: 100%;

    .positive {
        font-family: $text-bold !important;
        color: $color-success-500;
    }
    .negative {
        font-family: $text-bold !important;
        color: $color-error-500;
    }

    .topbar {
        width: 100%;
        height: 10%;
        display: flex;
        align-items: center;
        overflow: hidden;

        .content {
            display: flex;
            width: 60%;
            justify-content: flex-end;
        }

        button {
            height: 50px;
            width: 50px;
            font-size: 30px;
            background-color: #fff;
        }
        .sales-information {
            width: 40%;
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            flex-shrink: 0;
            height: 50px;
            gap: 8px;

            .nav {
                border-radius: 4px;
                display: flex;
                align-self: flex-start;
                width: 50px;
                height: 50px;
                align-items: center;
                &.sales-button {
                    @include background($image: img('chart_inactive.svg'), $size: 20px, $color: #fff);
                }
                &.active {
                    &.sales-button {
                        @include background($image: img('chart_active.svg'), $size: 20px, $color: $main);
                    }
                }
                &.mail {
                    @include background($image: img('mail.svg'), $size: 25px, $color: #fff);
                }
                &.refresh {
                    @include background($image: img('reload.svg'), $size: 20px, $color: #fff);
                }
            }
        }
        .navBar {
            width: calc(100% - 100px);
            display: grid;
            justify-items: start;
            grid-template-columns: repeat(3, 1fr);
            gap: 10px;

            .nav-elements {
                //width: 250px;
                @include display-flex();
                @include align-items();
                gap: 8px;

                .printer {
                    border-radius: 4px;
                    display: flex;
                    align-self: flex-start;
                    width: 50px;
                    height: 50px;
                    align-items: center;
                    @include background($image: img('printer_b3b3b3.svg'), $size: 20px, $color: #fff);
                }
                .timeframe {
                    @include display-flex();
                    flex-direction: column;

                    .title {
                        text-transform: uppercase;
                        color: $color-black;
                        @include font-size(m);
                        font-family: $text-bold;
                    }

                    .description {
                        color: $color-neutral-600;
                        @include font-size(xs);
                        font-family: $text;
                        line-height: 14px;
                    }
                }
            }

            .nav {
                border-radius: 4px;
                width: 50px;
                height: 50px;

                &.left {
                    @include background($image: img('left_dark.svg'), $size: 20px, $color: #fff);
                }
                &.right {
                    @include background($image: img('right_dark.svg'), $size: 20px, $color: #fff);
                }
            }
            .hours {
                width: calc(100% - 100px);
                height: 50px;
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                overflow-x: hidden;
                scroll-behavior: smooth;
                gap: 25px;
                padding: 0 0px;

                .scroll-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: 0 0 80px;
                    height: 100%;
                    width: 100px;
                    max-width: 100px;
                    min-width: 100px;
                    margin-left: 5px;

                    p {
                        font-family: $text;
                        font-size: 20px;
                        color: $color-neutral-600;
                    }
                }
                & .selected {
                    border: 2px solid $color-warning-500;
                    p {
                        font-family: $text-bold;
                    }
                }
            }
        }
        .summary-title {
            text-align: center;
            width: 125px;
            flex-shrink: 0;

            span {
                word-wrap: break-word;
                @include font-size(m);
                color: $color-neutral-600;
                font-family: $text-bold;
                text-transform: uppercase;
            }
        }
    }
    .content-sections {
        height: 90%;
        overflow: auto;
        .sales-section {
            width: 100%;
            display: flex;
            flex-direction: row;
            .sales-text {
                width: 40%;
                padding-left: 40px;
                span {
                    display: block;
                    font-family: $text;
                    display: flex;
                    align-items: center;
                    height: 35px;
                }
            }
            .sales-content {
                width: 60%;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                box-sizing: border-box;
                overflow: hidden;
                .sales-forecast {
                    width: calc(100% - 100px);
                    display: grid;
                    flex-direction: column;
                    grid-template-rows: repeat(4, 1fr);
                    align-items: center;
                    overflow-x: hidden;
                    overflow-y: hidden;
                    scroll-behavior: smooth;

                    .row {
                        display: grid;
                        flex-direction: row;
                        gap: 25px;
                        width: 100%;
                        grid-template-columns: repeat(3, 1fr);
                        text-align: center;

                        span {
                            text-align: center;
                        }

                        .scroll-item {
                            flex: 0 0 80px;
                            height: 35px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100px;
                            max-width: 100px;
                            min-width: 100px;
                            margin-left: 5px;

                            span {
                                text-align: center;
                                width: 100px;
                                font-family: $text;

                                &.positive {
                                    font-family: $text-bold;
                                    color: $color-success-500;
                                }
                                &.negative {
                                    font-family: $text-bold;
                                    color: $color-error-500;
                                }
                            }
                        }
                    }
                }
            }
            .sales-summary {
                width: 125px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                span {
                    font-family: $text;
                    display: flex;
                    align-items: center;
                    height: 35px;
                }
            }
        }
        .product-content {
            display: flex;
            flex-direction: row;
            width: 100%;
            margin-top: 16px;

            .products {
                width: 40%;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .item {
                    width: 100%;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.6);
                    }
                    &.frost {
                        &:hover {
                            background-color: $main-t90;
                        }
                    }

                    .icon {
                        width: 35px;
                        height: 35px;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 90%;
                        display: inline-block;
                    }
                    .content {
                        flex-direction: column;
                        width: 100%;
                        padding-left: 8px;
                        .name {
                            display: flex;
                            align-items: center;
                            font-family: $text-bold;
                            text-transform: uppercase;
                            @include font-size(sm);
                        }
                        .icon {
                            /* background-image: img('/img/defrost.svg'); */
                            height: 25px;
                            width: 25px;
                        }
                        .container {
                            display: block;
                            font-family: $text;
                            color: $color-neutral-600;
                            display: block;
                        }
                    }
                }
            }

            .content {
                display: flex;
                width: 60%;
                justify-content: flex-end;

                .forecast {
                    width: calc(100% - 100px);
                    display: flex;
                    flex-direction: column;
                    align-content: center;
                    align-items: stretch;
                    flex-direction: column;
                    gap: 10px;

                    .item {
                        height: 50px;
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        flex-direction: row;
                        align-items: center;
                        overflow-x: hidden;
                        scroll-behavior: smooth;
                        padding: 0;
                        gap: 25px;
                        width: 100%;

                        .value {
                            font-family: $text;
                            @include font-size(m);
                            display: block;
                            height: 45px;
                            background: #fff;
                            border: 1px solid #ddd;
                            border-radius: 3px;
                            @include display-flex();
                            @include align-items();
                            @include justify-content();
                            position: relative;
                            //width: 250px;

                            .increase {
                                display: block;
                                position: absolute;
                                bottom: 0px;
                                @include font-size(xs);
                                display: flex;
                                align-items: center;

                                &:before {
                                    display: inline-block;
                                    content: '';
                                    width: 0;
                                    height: 0;
                                    border-left: 5px solid transparent;
                                    border-right: 5px solid transparent;
                                    border-radius: 3px;
                                    margin-right: 2px;
                                }

                                &.positive {
                                    &:before {
                                        border-bottom: 6px solid green;
                                    }
                                }
                                &.negative {
                                    &:before {
                                        border-top: 6px solid #f00;
                                    }
                                }
                            }

                            &.current {
                                background-color: $color-warning-500;
                                border-color: $color-warning-500;
                                font-family: $text-bold;
                                color: #fff;

                                .value {
                                    color: #fff;
                                }
                                .increase {
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
            .summary {
                width: 125px;
                text-align: center;
                .item {
                    width: 100%;
                    height: 75px;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;

                    .value {
                        font-family: $text-bold;
                        @include font-size(l);
                        text-transform: uppercase;
                        text-align: center;
                    }
                }
            }
        }
    }
}

//RBI DEVICE
@media (max-width: 1285px) {
    .table {
        .topbar {
            .navBar {
                .nav-elements {
                    .timeframe {
                        .title {
                            @include font-size(sm);
                        }
                        .description {
                            @include font-size(xs);
                        }
                    }
                    .printer {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
    }
}
</style>
